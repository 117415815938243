const purple = '#67627c';
const green = '#95C996';

export default {
  primary: purple,
  secondary: green,
  white: '#fff',
  black: '',
  light: '#ccc',
  dark: '',
  purple,
  green,
  red: '',

};
