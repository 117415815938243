import React from 'react';
import Hours from './hours';

const Footer = () => (
  <footer className="footer center has-background-light content">
    <div className="container">
      <div className="columns">
        <div className="column">
          <h2 className="title is-3">Address</h2>
          <p>
            73 Princeton St #201
            <br />
            Chelmsford, MA 01863
          </p>
          <p>
            <a className="button is-primary" href="https://goo.gl/maps/aeaK99gscqu">
              Get Directions
            </a>
          </p>
        </div>
        <div className="column">
          <h2 className="title is-3">Contact Us</h2>
          <p>
            <strong>Phone:</strong> <br />
            <a href="tel:+19782568501">(978) 256-8501</a>
          </p>
          <p>
            <strong>Email:</strong> <br />
            <a href="mailto:info@boroyaneyeassociates.com">info@boroyaneyeassociates.com</a>
          </p>
        </div>

        <div className="column">
          <Hours />
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
