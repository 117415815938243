import React from 'react';

const LogoSmall = props => (
  <svg viewBox="0 0 948 125" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill={props.primary} fillRule="nonzero">
        <path d="M21 18.7h44.7c11 0 18.7 2.7 23.8 7.7 3.5 3.6 5.6 8.2 5.6 14.2v.2c0 10.1-5.7 16.1-13.4 19.4C92.3 63.7 99 69.7 99 81.5v.2c0 14.9-12.2 24-33 24H21v-87zm50.3 26.9c0-4.9-3.9-7.5-11-7.5H44.5v15.3h15.2c7.3 0 11.6-2.5 11.6-7.6v-.2zM63 70.4H44.5v16.1h18.7c7.5 0 11.6-2.9 11.6-8v-.2c0-4.8-3.8-7.9-11.8-7.9zM107.1 62.6v-.2c0-25 20.2-45.3 47.1-45.3C181.1 17 201 37 201 62.1v.2c0 25-20.2 45.3-47.1 45.3-26.9 0-46.8-20-46.8-45zm69.1 0v-.2c0-12.7-9-23.5-22.3-23.5s-22.2 10.7-22.2 23.3v.2c0 12.6 9.1 23.5 22.4 23.5 13.3-.1 22.1-10.8 22.1-23.3zM213.4 18.7h41.2c13.3 0 22.5 3.5 28.4 9.3 5.1 5.1 7.7 11.7 7.7 20.3v.2c0 13.4-7.1 22.3-17.9 26.9l20.8 30.4h-27.8l-17.6-26.4h-10.7v26.4h-24.1V18.7zm40.2 41.9c8.1 0 12.9-4 12.9-10.3V50c0-7-5.1-10.5-13.1-10.5h-15.9v21h16.1v.1zM300.1 62.6v-.2c0-25 20.2-45.3 47.1-45.3 26.9 0 46.8 20 46.8 45.1v.2c0 25-20.2 45.3-47.1 45.3-26.9-.1-46.8-20.1-46.8-45.1zm69 0v-.2c0-12.7-9-23.5-22.3-23.5s-22.2 10.7-22.2 23.3v.2c0 12.6 9.1 23.5 22.4 23.5 13.3-.1 22.1-10.8 22.1-23.3zM423.5 73.3l-33.2-54.6h27.5l17.9 31.7 18-31.7h27l-33.1 54.1v33h-24.1V73.3zM504.7 18.1H528l37.1 87.8h-25.9L533 90.2h-33.7l-6.3 15.7h-25.3l37-87.8zm21.2 53.3l-9.7-24.6-9.8 24.6h19.5zM573.5 18.7H596l35.9 46.1V18.7h23.9v87.1h-21L597.5 58v47.8h-23.9V18.7z" />
        <g opacity={0.5}>
          <path d="M689 19h64.312v13.664h-49.008v22.658h43.506v13.664h-43.506v23.35H754V106h-65zM799.356 71.649L765 19h18.037l24.049 38.321L831.65 19H849l-34.356 52.304V106h-15.288zM862 19h64.312v13.664h-49.008v22.658h43.506v13.664h-43.506v23.35H927V106h-65z" />
        </g>
      </g>
    </g>
  </svg>
);

export default LogoSmall;
