import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import '../styles/style.scss';
import SEO from './seo';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, title, description, footer }) => {
  return (
    <div>
      <Header />

      <SEO title={title} description={description} />

      {children}
      {footer && <Footer />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
  title: 'Boroyan Eye Associates',
  description: 'For the lifetime care of your eyes.',
  footer: true,
};

export default Layout;
