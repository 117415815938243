import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ type, children, color, size, fullWidth, isOutlined, inverted, rounded, loading, onPress }) => {
  function handleClick(e) {
    e.preventDefault();
    console.log('The link was clicked.');
  }

  let buttonClass = 'button';

  if (color) {
    buttonClass = buttonClass.concat(` is-${color}`);
  }

  if (size) {
    buttonClass = buttonClass.concat(` is-${size}`);
  }

  if (fullWidth) {
    buttonClass = buttonClass.concat(` is-full-width`);
  }

  if (isOutlined) {
    buttonClass = buttonClass.concat(` is-outlined`);
  }

  if (inverted) {
    buttonClass = buttonClass.concat(` is-inverted`);
  }

  if (rounded) {
    buttonClass = buttonClass.concat(` is-rounded`);
  }

  if (loading) {
    buttonClass = buttonClass.concat(` is-loading`);
  }

  return (
    <>
      {
        {
          anchor: (
            <a className={buttonClass} href="" onClick={onPress}>
              {children}
            </a>
          ),
          button: (
            <button className={buttonClass} type="button" onClick={onPress}>
              {children}
            </button>
          ),
          submit: (
            <button className={buttonClass} type="submit" onClick={onPress}>
              {children}
            </button>
          ),
          reset: (
            <button className={buttonClass} type="reset" onClick={onPress}>
              {children}
            </button>
          ),
        }[type]
      }
    </>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['anchor', 'button', 'submit', 'reset']),
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  type: 'anchor',
};

export default Button;
