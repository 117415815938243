import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Navigation from './navigation';
import Logo from './logoSmall';
import LogoSmall from './logoSmall';
import Glasses from './glassesSvg';
import color from '../styles/color';

const Header = ({ siteTitle }) => (
  <>
    <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <LogoSmall primary={color.white} height={30} />
          {/* <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28" /> */}
        </a>

        <Navigation />
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <Link className="navbar-item" to="/about/">
            About Us
          </Link>
          <Link className="navbar-item" to="/eye-care/">
            Eye Care
          </Link>
          <Link className="navbar-item" to="/products/">
            Products
          </Link>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <Link className="button" to="/request/">
              Request Appointment
            </Link>
          </div>
        </div>
      </div>
    </nav>
    {/* <div className="notification is-warning" style={{ marginBottom: 0, borderRadius: 0 }}>
      <div className="level">
        <p>
          <strong>COVID-19 Updates:</strong> Many COVID-19 restrictions have been eased. Mask requirements remain in place for the office.
        </p>
        <Link className="button is-dark" to="/reopening">
          More Information
        </Link>
      </div>
    </div> */}
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
