import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react/';
import { Location } from '@reach/router';

import color from '../styles/color';
import Button from './button';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }));
  }
  render() {
    const { isActive } = this.state;

    const modalClass = classnames({
      modal: true,
      'is-active': isActive,
    });

    const modalStyles = {
      fontSize: '3em',
    };

    return (
      <>
        <a role="button" className="navbar-burger" aria-label="menu" onClick={this.handleToggle}>
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>

        {isActive && (
          <FocusTrap>
            <div className={modalClass}>
              <div className="modal-background" style={{ backgroundColor: color.primary }} />
              <div className="modal-content ">
                <section className="section is-size-1">
                  <Location>
                    {({ location }) => (
                      <ul>
                        <li>
                          <Link className="has-text-white has-text-weight-bold" to="/about">
                            About Us
                          </Link>
                        </li>

                        <li>
                          <Link className="has-text-white has-text-weight-bold" to="/eye-care">
                            Eye Care
                          </Link>
                        </li>
                        <li>
                          <Link className="has-text-white has-text-weight-bold" to="/products">
                            Products
                          </Link>
                        </li>
                        {location.pathname != '/request/' && (
                          <li>
                            <Link
                              className="button is-large is-outline"
                              to="/request"
                              onClick={this.handleToggle}
                            >
                              Request Appointment
                            </Link>
                          </li>
                        )}
                      </ul>
                    )}
                  </Location>
                </section>
              </div>
              <button
                className="modal-close is-large"
                aria-label="close"
                // style={{ width: 96, height: 96, maxWidth: 96, maxHeight: 96 }}
                onClick={this.handleToggle}
              />
            </div>
          </FocusTrap>
        )}
      </>
    );
  }
}

// Navigation.propTypes = {
//   isActive: PropTypes.bool,
//   onClose: PropTypes.func,
// };

// Navigation.defaultProps = {
//   isActive: true,
//   onClose: null,
// };

export default Navigation;
