import React from 'react';

export default function Hours() {
  return (
    <div>
      <h2 className="title is-3">Hours</h2>
      <p>
        Monday: 8:30am - 8:00pm
        <br />
        Tuesday: 8:30am - 5:30pm
        <br />
        Wednesday: 8:30am - 5:30pm
        <br />
        Thursday: 8:30am - 5:30pm
        <br />
        Friday: 7:00am - 1:00pm
        <br />
        Saturday: closed
        <br />
        Sunday: closed
      </p>
    </div>
  );
}
