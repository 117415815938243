import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Hero({ title, subtitle, children, color, size, fluid }) {

  let heroClass = 'hero';

  if (color) {
    heroClass = heroClass.concat(` is-${color}`);
  }

  if (size) {
    heroClass = heroClass.concat(` is-${size}`);
  }

  const containerClass = fluid ? 'container is-fluid' : 'container';

  return (
    <section className={heroClass}>
      <div className="hero-body">
        <div className={containerClass}>
          {title && <h1 className="title is-1">{title}</h1>}
          {subtitle && <h2 className="subtitle is-4">{subtitle}</h2>}
          {children}
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'light', 'dark']),
  size: PropTypes.oneOf(['medium', 'large', 'fullheight', 'fullheight-with-navbar']),
};

Hero.defaultProps = {
  children: null,
  title: null,
  subtitle: null,
  color: null,
  size: null,
};

export default Hero;
